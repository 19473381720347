<mat-card>
  <mat-card-content>
      <mat-card-title>{{ title }}</mat-card-title>
      <div fxLayout="row" fxLayoutGap="5" class="tile-row">
          <span class="title-text" fxFlex="50%" i18n>{{ title1 }}</span>
          <span class="tile-value" fxFlex="50%" [ngClass]="getColorClass(value1, isColored1)">
              {{ value1 | currency }}</span>
      </div>

      <div *ngIf="value2 != null" fxLayout="row" fxLayoutGap="5" class="tile-row">
          <span class="title-text" fxFlex="50%" i18n>{{ title2 }}</span>
          <span class="tile-value" fxFlex="50%"  [ngClass]="getColorClass(value2, isColored2)">
              {{ value2 | currency }}
          </span>
      </div>
  </mat-card-content>
</mat-card>