import { ActivatedRoute } from '@angular/router';
import { PasswordConfirmationValidatorService } from '../custom-validators/password-confirmation-validator.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/infrastructure/services/UsersService';
import { ResetPasswordDto } from 'src/app/infrastructure/dtos/Users/resetPasswordDto ';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup | undefined;
  showSuccess: boolean = false;
  showError: boolean = false;
  errorMessage: string | undefined;
  private token: string | undefined;
  private email: string | undefined;
  
  constructor(private userService: UsersService, private passConfValidator: PasswordConfirmationValidatorService, 
    private route: ActivatedRoute) { }
  
    ngOnInit(): void {
      this.resetPasswordForm = new FormGroup({
        password: new FormControl('', [Validators.required]),
        confirm: new FormControl('')
    });
    
    this.resetPasswordForm?.get('confirm')?.setValidators([Validators.required,
      this.passConfValidator.validateConfirmPassword(this.resetPasswordForm.get('password'))]);
    
      this.token = this.route.snapshot.queryParams['token'];
      this.email = this.route.snapshot.queryParams['email'];
  }

  public validateControl = (controlName: string) => {
    return this.resetPasswordForm?.get(controlName)?.invalid && this.resetPasswordForm?.get(controlName)?.touched
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.resetPasswordForm?.get(controlName)?.hasError(errorName)
  }

  public resetPassword = (resetPasswordFormValue: any) => {
    this.showError = this.showSuccess = false;
    const resetPass = { ... resetPasswordFormValue };
    const resetPassDto: ResetPasswordDto = {
      password: resetPass.password,
      confirmPassword: resetPass.confirm,
      token: this.token!,
      email: this.email!
    }
    this.userService.resetPassword(resetPassDto)
    .subscribe({
      next:(_) => this.showSuccess = true,
    error: (err: HttpErrorResponse) => {
      this.showError = true;
      this.errorMessage = err.message;
    }})
  }
}