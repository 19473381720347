import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialUser, FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { UsersService } from 'src/app/infrastructure/services/UsersService';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserEmailAuthenticationDto } from 'src/app/infrastructure/dtos/Users/userEmailAuthenticationDto';
import { ExternalAuthDto } from 'src/app/infrastructure/dtos/Users/externalAuthDto';
import { catchError, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserPorfolioService } from 'src/app/infrastructure/services/userPortfolioService';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private returnUrl: string | undefined;

  GoogleLoginProvider = GoogleLoginProvider;
  user: SocialUser | undefined;
  loggedIn: boolean = false;
  loginForm: FormGroup | undefined;
  errorMessage: string = '';
  showError: boolean = false;
  loginLoading: boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private socialAuthService: SocialAuthService,
    private userPortfolioService: UserPorfolioService,
    private userService: UsersService) {
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required])
    });
    // TODO: return url
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    this.returnUrl = '/dashboard';

    this.socialAuthService.authState.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);

      const externalAuth: ExternalAuthDto = {
        provider: user.provider,
        idToken: user.idToken
      }
      this.validateExternalAuth(externalAuth);
    });
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  refreshGoogleToken(): void {
    this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  validateControl = (controlName: string) => {
    return this.loginForm?.get(controlName)?.invalid && this.loginForm?.get(controlName)?.touched
  }
  hasError = (controlName: string, errorName: string) => {
    return this.loginForm?.get(controlName)?.hasError(errorName)
  }

  externalLogin = () => {
    this.showError = false;
    this.userService.signInWithGoogle();
    this.userService.extAuthChanged.pipe(untilDestroyed(this)).subscribe(user => {
      const externalAuth: ExternalAuthDto = {
        provider: user.provider,
        idToken: user.idToken
      }
      this.validateExternalAuth(externalAuth);
    })
  }

  private validateExternalAuth(externalAuth: ExternalAuthDto) {
    this.userService.externalLogin(externalAuth).pipe(
      untilDestroyed(this),
      catchError(err => {
        this.errorMessage = err.message;
        this.showError = true;
        this.loginLoading = false;
        this.userService.signOutExternal();
        return of();
      }),
      tap(res => {
        localStorage.setItem("token", res.token);
        this.userService.sendAuthStateChangeNotification(res.isAuthSuccessful);
      }),
      switchMap(_ => this.userPortfolioService.initUserPortfolio()))
    .subscribe(_ => {
      this.router.navigate([this.returnUrl]);
      this.loginLoading = false;
    });
  }

  emailLogin = (loginFormValue: any) => {
    this.loginLoading = true;
    this.showError = false;
    const login = { ...loginFormValue };
    const userForAuth: UserEmailAuthenticationDto = {
      email: login.username,
      password: login.password,
      clientURI: `${environment.clientBaseUrl}/auth/forgotpassword`
    }
    this.userService.emailLoginUser(userForAuth).pipe(
      untilDestroyed(this),
      catchError(err => {
        this.errorMessage = err.message;
        this.showError = true;
        this.loginLoading = false;
        return of();
      }),
      tap(res => {
        localStorage.setItem("token", res.token);
        this.userService.sendAuthStateChangeNotification(res.isAuthSuccessful);
      }),
      switchMap(_ => this.userPortfolioService.initUserPortfolio()))
    .subscribe(_ => {
      this.router.navigate([this.returnUrl]);
      this.loginLoading = false;
    })
  }
}
