export class CurrentUserDto {
    id!: string;
    email!: string;
    userName!: string;

    constructor(id: string, email: string, userName: string) {
        this.id = id;
        this.email = email;        
        this.userName = userName;
    }
}