import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, ReplaySubject } from "rxjs";
import { BaseApiService } from "./BaseApiService";
import { GetUserPortfolioDto } from "../dtos/getUserPortfolioDto";
import { UserPortfolioDto } from "../dtos/userPortfolioDto";

@Injectable({
    providedIn: 'root',
})
export class UserPorfolioService extends BaseApiService {

    public $userPorfolios: ReplaySubject<GetUserPortfolioDto[]> = new ReplaySubject<GetUserPortfolioDto[]>;

    constructor(private http: HttpClient,
    ) {
        super();
    }

    public initUserPortfolio(): Observable<GetUserPortfolioDto> {
        return this.getuserPortfolios()
            .pipe(
                map(userPortfolios => {
                    this.$userPorfolios.next(userPortfolios);

                    let activePortfolio = this.getActivePortfolio();
                    const isActivePortfolioValid = userPortfolios.some(q => q.id === activePortfolio?.id);

                    // fallback for invalid data from local storage
                    if (!activePortfolio || !isActivePortfolioValid) {
                        activePortfolio = userPortfolios[0];
                        this.setActivePortfolio(activePortfolio);
                    }

                    return activePortfolio;
                })
            );
    }

    public getuserPortfolios(): Observable<GetUserPortfolioDto[]> {
        const url = this.BaseUrl + 'UserPortfolios/GetAll';
        return this.http.get<GetUserPortfolioDto[]>(url);
    }

    public setActivePortfolio(userPortfolio: GetUserPortfolioDto) {
        localStorage.setItem("activePortfolio", JSON.stringify(userPortfolio));
    }

    public getActivePortfolio(): GetUserPortfolioDto | null {
        const active = localStorage.getItem("activePortfolio");
        if (!active) return null;
        return JSON.parse(active);
    }

    public getDateTimeFormat(): string {
        return this.getActivePortfolio()?.dateFormat || 'dd.MM.yyyy';
    }

    public getUserPortfolio(id: number): Observable<GetUserPortfolioDto[]> {
        const url = this.BaseUrl + 'UserPortfolios/GetById';
        let queryParams = new HttpParams();
        queryParams = queryParams.append("id", id);
        return this.http.get<GetUserPortfolioDto[]>(url, { params: queryParams });
    }

    public addUserPortfolio(dto: UserPortfolioDto): Observable<GetUserPortfolioDto> {
        const url = `${this.BaseUrl}userPortfolios/AddUserPortfolio`;
        return this.http.post<GetUserPortfolioDto>(url, dto);
    }

    public editUserPortfolio(dto: UserPortfolioDto): Observable<GetUserPortfolioDto> {
        const url = `${this.BaseUrl}userPortfolios/EditUserPortfolio`;
        return this.http.put<GetUserPortfolioDto>(url, dto);
    }

    public deleteUserPortfolio(id: number): Observable<void> {
        const url = `${this.BaseUrl}userPortfolios/DeleteUserPortfolio/${id}`;
        return this.http.delete<void>(url);
    }
}