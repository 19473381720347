import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [ 
      {
        path: 'login', 
        component: LoginComponent
      },
      {
        path: 'registration', 
        component: RegistrationComponent
      },
      { 
        path: 'forgotpassword',
        component: ForgotPasswordComponent
      },
      { 
        path: 'resetpassword',
        component: ResetPasswordComponent
      },
      { 
        path: 'emailconfirmation',
        component: EmailConfirmationComponent
      }  
    ]
  },
  
  
  // {
  //   path: 'login',
  //   component: AuthLayoutComponent,
  //   children: [
  //   {
  //     path: '', 
  //     component: LoginComponent
  //   }],
  // },
  // {
  //   path: 'registration', 
  //   component: AuthLayoutComponent,
  //   children: [
  //   {
  //     path: '', 
  //     component: RegistrationComponent
  //   }],
  // },
  // { 
  //   path: 'forgotpassword', 
  //   component: AuthLayoutComponent,
  //   children: [
  //   {
  //     path: '', 
  //     component: ForgotPasswordComponent
  //   }], 
  // },
  // { 
  //   path: 'resetpassword', 
  //   component: AuthLayoutComponent,
  //   children: [
  //   {
  //     path: '', 
  //     component: ResetPasswordComponent
  //   }],
  // },
  // { 
  //   path: 'emailconfirmation', 
  //   component: AuthLayoutComponent,
  //   children: [
  //   {
  //     path: '', 
  //     component: EmailConfirmationComponent
  //   }], 
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
