<h2 mat-dialog-title i18n *ngIf="!isEdit">Add user portfolio</h2>
<h2 mat-dialog-title i18n *ngIf="isEdit">Edit user portfolio</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <mat-form-field appearance="fill">
            <mat-label i18n>Name</mat-label>
            <input matInput type="text" formControlName="name" name="name">
            <mat-error *ngIf="form.get('name')?.hasError('required')" i18n>This field is required</mat-error>
            <mat-error *ngIf="form.get('name')?.hasError('maxlength')" i18n>Max length is 15 characters</mat-error>
        </mat-form-field>
    </div>

    <div mat-dialog-content>
        <mat-form-field appearance="fill">
            <mat-label i18n>Currency</mat-label>
            <mat-select formControlName="currencyId" name="currencyId">
                <mat-option *ngFor="let ce of currencies" [value]="ce.id">
                    {{ce.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('currencyId')?.hasError('required')" i18n>This field is required</mat-error>
        </mat-form-field>
    </div>

    <div mat-dialog-content>
        <mat-form-field appearance="fill">
            <mat-label i18n>Date format</mat-label>
            <mat-select formControlName="dateFormat" name="dateFormat">
                <mat-option *ngFor="let df of dateFormats" [value]="df">
                    {{df}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('paymentPeriod')?.hasError('required')" i18n>This field is required</mat-error>
        </mat-form-field>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button type="button" (click)="onNoClick()" i18n>Cancel</button>
        <button mat-raised-button color="primary"type="submit" cdkFocusInitial i18n>Submit</button>
    </div>
</form>