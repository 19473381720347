import { environment } from "src/environments/environment";

export class BaseApiService {
    // todo: from config
    //public readonly BaseUrl: string = 'http://localhost:49156/api/'; Docker
    public readonly BaseUrl: string = environment.apiUrl;//'http://localhost:5009/api/';

    public getActivePortfolioId(): number  {
        const active = localStorage.getItem("activePortfolio");
        if (!active) throw new Error("Active portfolio error");
        const parsed = JSON.parse(active);
        return parsed.id;
    }
}