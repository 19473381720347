import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationResponseDto } from 'src/app/infrastructure/dtos/Users/registrationResponseDto';
import { UserEmailRegistrationDto } from 'src/app/infrastructure/dtos/Users/userEmailRegistrationDto';
import { UsersService } from 'src/app/infrastructure/services/UsersService';
import { environment } from 'src/environments/environment';
import { PasswordConfirmationValidatorService } from '../custom-validators/password-confirmation-validator.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  public registerForm: FormGroup | undefined;
  public errorMessage: string = '';
  public showError: boolean | undefined;
  loading: boolean = false;


  constructor(private userService: UsersService,
    private passConfValidator: PasswordConfirmationValidatorService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      confirm: new FormControl('')
    });

    this.registerForm?.get('confirm')?.setValidators([Validators.required,
      this.passConfValidator.validateConfirmPassword(this.registerForm?.get('password'))]);
  }

  public validateControl = (controlName: string) => {
    return this.registerForm?.get(controlName)?.invalid && this.registerForm?.get(controlName)?.touched
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.registerForm?.get(controlName)?.hasError(errorName)
  }

  public registerUser = (registerFormValue: any) => {
    this.showError = false;
    const formValues = { ...registerFormValue };
    const user: UserEmailRegistrationDto = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      password: formValues.password,
      confirmPassword: formValues.confirm,
      clientURI: `${environment.clientBaseUrl}/auth/emailconfirmation`
    };

    this.loading = true;
    this.userService.registerUser(user)
    .subscribe({
      next: (_) => {
        this.loading = false;
        this.router.navigate(["/auth/login"]);
      },
      error: (err: any) => {
        this.loading = false;
        this.errorMessage = err.message;
        this.showError = true;
      }
    })
  }
}
