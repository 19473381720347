import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ForgotPasswordDto } from 'src/app/infrastructure/dtos/Users/forgotPasswordDto';
import { UsersService } from 'src/app/infrastructure/services/UsersService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup | undefined;
  successMessage: string | undefined;;
  errorMessage: string | undefined;;
  showSuccess: boolean | undefined;;
  showError: boolean | undefined;;
  
  constructor(private userService: UsersService) { }
  
  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl("", [Validators.required])
    })
  }
  public validateControl = (controlName: string) => {
    return this.forgotPasswordForm?.get(controlName)?.invalid && this.forgotPasswordForm?.get(controlName)?.touched
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.forgotPasswordForm?.get(controlName)?.hasError(errorName)
  }
  public forgotPassword = (forgotPasswordFormValue: any) => {
    this.showError = this.showSuccess = false;
    const forgotPass = { ...forgotPasswordFormValue };
    const forgotPassDto: ForgotPasswordDto = {
      email: forgotPass.email,
      clientURI: `${environment.clientBaseUrl}/auth/resetpassword`
    }
    this.userService.forgotPassword(forgotPassDto)
    .subscribe({
      next: (_) => {
      this.showSuccess = true;
      this.successMessage = 'The link has been sent, please check your email to reset your password.'
    },
    error: (err: HttpErrorResponse) => {
      this.showError = true;
      this.errorMessage = err.message;
    }})
  }
}
