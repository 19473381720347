import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/infrastructure/services/UsersService';
import { UserPorfolioService } from 'src/app/infrastructure/services/userPortfolioService';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GetUserPortfolioDto } from 'src/app/infrastructure/dtos/getUserPortfolioDto';
import { MatMenuTrigger } from '@angular/material/menu';
import { filter, switchMap, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddEditUserPortfolioDialogComponent } from './components/add-edit-user-portfolio-dialog/add-edit-user-portfolio-dialog.component';
import { UserPortfolioDto } from 'src/app/infrastructure/dtos/userPortfolioDto';
import { DialogDeleteConfirmComponent } from 'src/app/shared/dialog-delete-confirm/dialog-delete-confirm.component';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppHeaderComponent implements OnInit {

  public isUserAuthenticated: boolean | undefined;
  photoUrl: string | undefined;
  userName: string | undefined;
  userPorfolios: GetUserPortfolioDto[] = [];
  activeUserPortfolio: GetUserPortfolioDto | null | undefined;

  constructor(private router: Router,
    private userService: UsersService,
    private dialog: MatDialog,
    private userPorfolioService: UserPorfolioService) {
      this.userService.authChanged.subscribe(res => {
        this.isUserAuthenticated = res;

        if (this.isUserAuthenticated) {
          this.userName = this.userService.getCurrentUser().userName;
        }
      });

      this.userPorfolioService.$userPorfolios.pipe(untilDestroyed(this))
      .subscribe(q => {
        this.userPorfolios = q;
        const activePortfolio = this.userPorfolioService.getActivePortfolio();
        this.activeUserPortfolio = activePortfolio;
      })
  }
  
  ngOnInit() {
   
  }

  // mouseUserPortfolioEnter(trigger: MatMenuTrigger) {
  //   trigger.openMenu();
  // }

  // mouseUserPortfolioLeave(trigger: MatMenuTrigger) {
  //   trigger.closeMenu();
  // }

  addUserPortfolio() {
    let dto = new UserPortfolioDto();
    const dialogRef = this.dialog.open(AddEditUserPortfolioDialogComponent, {
      //width: '250px',
      data: dto,
    });

    dialogRef.afterClosed()
      .pipe(
        untilDestroyed(this),
        filter(q => q != undefined),
        switchMap(q => this.userPorfolioService.addUserPortfolio(q)),
      )
      .subscribe(q => {
        this.userPorfolios.push(q);
      });
  }

  editUserPortfolio(id: number) {
    this.userPorfolioService.getUserPortfolio(id).pipe(untilDestroyed(this))
    .subscribe(up => {
      const dialogRef = this.dialog.open(AddEditUserPortfolioDialogComponent, {
        data: up,
      });
  
      dialogRef.afterClosed()
        .pipe(
          untilDestroyed(this),
          filter(q => q != undefined),
          switchMap(q => this.userPorfolioService.editUserPortfolio(q)),
        )
        .subscribe(q => {
          this.userPorfolioService.setActivePortfolio(q);
        });
    })
  }

  deleteUserPortfolio(item: GetUserPortfolioDto) {
    const dialogRef = this.dialog.open(DialogDeleteConfirmComponent);

    dialogRef.afterClosed()
      .pipe(
        untilDestroyed(this),
        filter(q => q != undefined),
        switchMap(_ =>  this.userPorfolioService.deleteUserPortfolio(item.id)),
        tap(q => {
          const index = this.userPorfolios.indexOf(item);
          this.userPorfolios.splice(index, 1);
        }),
      )
      .subscribe();
  }

  selectUserPortfolio(userPortoflio: GetUserPortfolioDto) {
    this.userPorfolioService.setActivePortfolio(userPortoflio);
    this.router.navigate(["/dashboard"]);
    window.location.reload();
  }

  signOut(): void {
    this.userService.signOut();
    this.router.navigate(["/"]);
    //this.router.navigate(['login']);
  }
}
