import { Routes } from '@angular/router';
import { AuthGuardService } from './infrastructure/guards/auth-guard.service';
import { LandingPageOverviewComponent } from './landing-page/landing-page-overview/landing-page-overview.component';
import { FullComponent } from './layouts/full/full.component';
import { ForbiddenComponent } from './shared/forbidden/forbidden.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

export const AppRoutes: Routes = [
  {
    path: 'home',
    component: LandingPageOverviewComponent,
  },
  { path: '', redirectTo: '/auth/login', pathMatch: 'full'},
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'stocks',
        loadChildren: () => import('./stocks/stocks.module').then(m => m.StocksModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'cryptos',
        loadChildren: () => import('./cryptos/cryptos.module').then(m => m.CryptosModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'bonds',
        loadChildren: () => import('./bonds/bonds.module').then(m => m.BondsModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'real-estates',
        loadChildren: () => import('./real-estates/real-estates.module').then(m => m.RealEstatesModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'calendar',
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [AuthGuardService]
      }
    ],
    canActivate: [AuthGuardService]
  },
  { 
    path: '**', 
    component: NotFoundComponent 
  },
  { 
    path: 'forbidden', 
    component: ForbiddenComponent 
  },
];
