import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const MENUITEMS = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'attach_money' },
  { state: 'stocks', type: 'link', name: 'Stocks', icon: 'show_chart' },
  { state: 'cryptos', type: 'link', name: 'Cryptos', icon: 'rocket_launch' },
  { state: 'bonds', type: 'link', name: 'Bonds & p2p', icon: 'article' },
  { state: 'real-estates', type: 'link', name: 'Real Estates', icon: 'add_business', disabled: true },
  { state: 'cash-accounts', type: 'link', name: 'Cash accounts', icon: 'savings', disabled: true },
  { state: 'metals', type: 'link', name: 'Metals', icon: 'diamond', disabled: true },
  { state: 'collectibles', type: 'link', name: 'Collectibles', icon: 'watch', disabled: true },
  { state: 'calendar', type: 'link', name: 'Calendar', icon: 'calendar_month' },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
