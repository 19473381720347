
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule as cm, DateAdapter } from 'angular-calendar';
import { StocksService } from './infrastructure/services/StocksService';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { AuthGuardService } from './infrastructure/guards/auth-guard.service';
import { ErrorHandlerService } from './infrastructure/services/error-handler.service';
import { JwtModule } from "@auth0/angular-jwt";
import { AuthModule } from './auth/auth.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { SnackNotificationService } from './infrastructure/services/snack-notification-service';
import { AddEditUserPortfolioDialogComponent } from './layouts/full/header/components/add-edit-user-portfolio-dialog/add-edit-user-portfolio-dialog.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    AddEditUserPortfolioDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    LandingPageModule,
    ReactiveFormsModule,
    AuthModule,
    CurrencyMaskModule,
    RouterModule.forRoot(AppRoutes), //, { useHash: true }
    cm.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:5009", "80.211.196.86:81", "http://80.211.196.86:81", "cahfy-be-api.azurewebsites.net", "cashfy.app"],
        //disallowedRoutesRoutes: []
      }
    })
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerService,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1069927237311-odli46vd123bf3bq01mmb0cjbd219pfa.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('218076898670758')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: PathLocationStrategy
    // },
    
    StocksService,
    AuthGuardService,
    SnackNotificationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
