import { NgModule } from '@angular/core';
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrivacyComponent } from './privacy/privacy.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DialogDeleteConfirmComponent } from './dialog-delete-confirm/dialog-delete-confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TableComponentComponent } from '../stocks/components/table-component/table-component.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { NotFoundComponent } from './not-found/not-found.component';
import { UploadDndDialogComponent } from './upload-dnd-dialog/upload-dnd-dialog.component';
import { DragDropDirective } from './directives/dragDropDirective';
import { MatSelectModule } from '@angular/material/select';
import { CurrencyTileComponent } from './currency-tile/currency-tile.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const routes: Routes = [
];

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    PrivacyComponent,
    ForbiddenComponent,
    DialogDeleteConfirmComponent,
    NotFoundComponent,
    UploadDndDialogComponent,
    DragDropDirective,
    CurrencyTileComponent
    // TableComponentComponent,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CurrencyTileComponent
    // TableComponentComponent
   ],
   imports: [
      CommonModule,
      MatCardModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatToolbarModule,
      ReactiveFormsModule,
      MatDialogModule,
      MatPaginatorModule,
      FlexLayoutModule,
      MatTableModule,
      MatTableModule,
      MatSortModule,
      MatButtonModule,
      MatMenuModule,
      MatNativeDateModule,
      MatFormFieldModule,
      MatInputModule,
      MatIconModule,
      MatTabsModule,
      FormsModule,
      MatDialogModule,
      MatSelectModule,
      RouterModule.forChild(routes)
    ],
  providers: [ MenuItems ]
})
export class SharedModule { }
