import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-currency-tile',
  templateUrl: './currency-tile.component.html',
  styleUrls: ['./currency-tile.component.css']
})
export class CurrencyTileComponent implements OnInit {

  @Input() title!: string;
  @Input() title1!: string;
  @Input() title2!: string;
  @Input() value1!: any;
  @Input() value2!: any;
  @Input() isColored1!: boolean;
  @Input() isColored2!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  getColorClass(value: any, isColored: boolean) {
    return isColored && value > 0 ? 'positive-number' : 
      isColored  && value < 0   ? 'negative-number' : 'neutral-number'
  }
}
