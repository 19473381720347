import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, TitleStrategy } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs';
import { BreadCrumbModel } from 'src/app/infrastructure/models/breadCrumbModel';
import { MenuItems } from '../../shared/menu-items/menu-items';

@UntilDestroy()
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  breadCrumb: BreadCrumbModel | undefined;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    public menuItems: MenuItems,
    private route: ActivatedRoute,
    private titleSevice: Title
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() { }

  public title: string | undefined;

  ngOnInit() {
    this.title = this.titleSevice.getTitle();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.title = title;
          //this.titleService.setTitle(`My App - ${title}`);
        }
      });

    this.route.queryParams.pipe(untilDestroyed(this))
      .subscribe(params => {
        this.breadCrumb = new BreadCrumbModel();

        if (params) {
          const pname = params['pn'];
          const iname = params['in'];
          const tabId = parseInt(params['tabId']);
          const type = params['type'];
          if (type) {
            this.breadCrumb.pname = pname;
            this.breadCrumb.iname = iname;
            this.breadCrumb.tabId = tabId;
            this.breadCrumb.type = type;
          }
        } else {
          //this.route?.snapshot?.firstChild?.url[0].path
        }

        //console.log(params);
      }
      );
  }
}
