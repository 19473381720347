<form [formGroup]="registerForm" autocomplete="off" novalidate (ngSubmit)="registerUser(registerForm?.value)">
  <mat-card>
    <mat-card-title>Register</mat-card-title>
    <div class="card">
      <div class="card-body">
        <div *ngIf="showError" class="alert alert-danger" role="alert">
          <span [innerHTML]="errorMessage"></span>
        </div>

        <mat-card-content>
          <div fxLayout="column" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label i18n>First Name</mat-label>
              <input matInput type="text" id="firstName" formControlName="firstName" class="form-control">
            </mat-form-field>

            <mat-form-field>
              <mat-label i18n>Last Name</mat-label>
              <input matInput type="text" id="lastName" formControlName="lastName" class="form-control">
            </mat-form-field>

            <mat-form-field>
              <mat-label i18n>Email</mat-label>
              <input matInput type="email" id="email" formControlName="email" class="form-control">
              <div class="col-md-5">
                <em *ngIf="validateControl('email') && hasError('email', 'email')">Please provide a valid email</em>
                <em *ngIf="validateControl('email') && hasError('email', 'required')">Email is required</em>
              </div>
            </mat-form-field>

            <mat-form-field>
              <mat-label i18n>Password</mat-label>
              <input matInput type="password" id="password" formControlName="password" class="form-control">
              <div class="col-md-5">
                <em *ngIf="validateControl('password') && hasError('password', 'required')">Password is
                  required</em>
              </div>
            </mat-form-field>

            <mat-form-field>
              <mat-label i18n>Confirm Password</mat-label>
              <input matInput type="password" id="confirm" formControlName="confirm" class="form-control">
              <div class="col-md-5">
                <em *ngIf="validateControl('confirm') && hasError('confirm', 'required')">Confirmation is
                  required</em>
                <em *ngIf="hasError('confirm', 'mustMatch')">Passwords must match</em>
              </div>
            </mat-form-field>
          </div>

          <br>
          <div class="mb-3 row">
            <div class="col-md-1">
              <button type="submit" class="btn btn-info" [disabled]="!registerForm?.valid || loading" mat-raised-button
                color="primary">
                <mat-icon *ngIf="loading"><mat-spinner color="Warn" diameter="20"></mat-spinner></mat-icon>
                Register
              </button>
            </div>
          </div>

        </mat-card-content>
      </div>
    </div>

    <mat-card-actions>
      <div class="mb-3 row">

        <div class="reg-left"><a [routerLink]="['/auth/login']" i18n> Login </a></div>
      </div>
    </mat-card-actions>
  </mat-card>

</form>