// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  title: '[Dev] CashFY',
  //apiUrl: 'http://localhost:5009/api/',
  apiUrl: 'https://cahfy-be-api.azurewebsites.net/api/',
  clientBaseUrl: 'https://app.cashfy.app',
  //apiUrl: "http://80.211.196.86:81/api/"
};
