import {Injectable} from '@angular/core';
import { SocialUser, SocialAuthService } from '@abacritt/angularx-social-login';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import { UsersService } from '../services/UsersService';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,
    private socialAuthService: SocialAuthService,
    private userService: UsersService          
    ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 
    if (this.userService.isUserAuthenticated()) {
      return true;
    }
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
    
    return false;
  } 

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  //   return this.socialAuthService.authState.pipe(
  //     map((socialUser: SocialUser) => !!socialUser),
  //     tap((isLoggedIn: boolean) => {
  //       console.log(isLoggedIn);
  //       if (!isLoggedIn) {
  //         this.router.navigate(['login']);
  //       }
  //     })
  //   );
  // }
}