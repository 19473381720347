import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { FileHandle } from '../directives/dragDropDirective';

@Component({
  selector: 'app-upload-dnd-dialog',
  templateUrl: './upload-dnd-dialog.component.html',
  styleUrls: ['./upload-dnd-dialog.component.css']
})
export class UploadDndDialogComponent implements OnInit {

  selectedBrokerId: any;
  files: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<UploadDndDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public brokers: any[],
  ) { }

  ngOnInit(): void {
    // this.selectedBroker = this.brokers[0].id;
  }

  onFileDropped($event: any[]) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: any[]) {
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.dialogRef.close({ files: this.files, brokerId: this.selectedBrokerId });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
