<form [formGroup]="forgotPasswordForm" autocomplete="off" novalidate (ngSubmit)="forgotPassword(forgotPasswordForm?.value)">
    <mat-card>
        <mat-card-title i18n>Forgot Password</mat-card-title>
        <div class="card">
            <div class="card-body">
                <div *ngIf="showError" class="alert alert-danger" role="alert">
                    {{errorMessage}}
                </div>
                <div *ngIf="showSuccess" class="alert alert-success" role="alert">
                    {{successMessage}}
                </div>

                <mat-card-content fxLayout="column" fxLayoutAlign="space-around center">
                    <mat-form-field>
                        <mat-label i18n>Email</mat-label>
                        <input matInput type="email" id="email" formControlName="email" class="form-control" >
                        <div class="col-md-5">
                            <em *ngIf="validateControl('email') && hasError('email', 'required')">Email is required</em>
                        </div>
                    </mat-form-field>

                </mat-card-content>

                <br>
                <div class="mb-3 row">
                    <div class="col-md-1">
                        <button type="submit" [disabled]="!forgotPasswordForm?.valid" mat-raised-button color="primary">Submit</button>
                    </div>
                </div>
                <br>

            </div>
        </div>

    </mat-card>
</form>