<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container"> 
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" class="topbar telative">
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" [routerLink]="['/dashboard']">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!-- <img src="assets/images/logo-cashfy.png" alt="homepage" class="dark-logo"> -->
                    <!-- Light Logo icon -->
                    <img src="assets/images/logo-only.png" alt="homepage" class="light-logo">
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span fxShow="false" fxShow.gt-xs>
                    <!-- <h1>Cashfy</h1> -->
                    <img src="assets/images/text-only.png" class="light-logo" alt="homepage"> 
                 <!-- <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo">
                 <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"> -->
                </span> </a>
        </div>
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->
        
        <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
            <mat-icon>menu</mat-icon>
        </button>
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches"  >
            
                <app-sidebar></app-sidebar>
               
            
        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">
            <mat-toolbar class="topbar telative toolbar-color">
                <span *ngIf="breadCrumb && breadCrumb?.pname" class="toolbar-bc">
                    <a [routerLink]="[breadCrumb!.type ]" [state]="{ tabId: breadCrumb.tabId }" class="link" i18n> 
                        <mat-icon aria-hidden="false" aria-label="back" fontIcon="arrow_back"></mat-icon>
                        {{ breadCrumb!.pname }}
                    </a> 
                </span>
                <span class="toolbar-content">
                    <h4>{{ title }} {{ breadCrumb!.iname }}</h4>
                    <!-- <h4>{{ title }}</h4> -->
                </span>
            </mat-toolbar>

            <div class="page-content">

                <router-outlet><app-spinner></app-spinner></router-outlet>
                
            </div>    
               
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>