import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserPortfolioDto } from 'src/app/infrastructure/dtos/userPortfolioDto';
import { CurrencyDto } from 'src/app/infrastructure/dtos/currencyDto';
import { CommonService } from 'src/app/infrastructure/services/commonService';

@UntilDestroy()
@Component({
  selector: 'app-add-edit-user-portfolio-dialog',
  templateUrl: './add-edit-user-portfolio-dialog.component.html',
  styleUrls: ['./add-edit-user-portfolio-dialog.component.css']
})
export class AddEditUserPortfolioDialogComponent implements OnInit {

  dateFormats: string[] = [ 'dd.MM.yyyy', 'MM-dd-yyyy', 'MM/dd/yyyy'];
  currencies: CurrencyDto[] = [];
  isEdit = false;

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    currencyId: new FormControl(0, [Validators.required]),
    dateFormat: new FormControl('',[Validators.required]),
    // timezone: new FormControl('',[Validators.required]),
  });
  
  constructor(
    private commonService: CommonService,
    public dialogRef: MatDialogRef<AddEditUserPortfolioDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserPortfolioDto,
  ) {
  }

  ngOnInit() {
    this.commonService.$currencies.pipe(untilDestroyed(this))
    .subscribe(q => {
      this.currencies = q;
    })
    
    if (this.data?.id) this.isEdit = true;
    
    this.form.setValue({
      name: this.data?.name || null,
      currencyId: this.data.currency?.id || null,
      dateFormat: this.data?.dateFormat || null,
      // timezone: this.data.timezone || null,
    })
  }

  onSubmit() {
    this.data = Object.assign(this.data, this.form.value);

    if (this.form.valid) {
      this.dialogRef.close(this.data);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
