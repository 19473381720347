import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs';
import { UsersService } from 'src/app/infrastructure/services/UsersService';
import { CommonService } from './infrastructure/services/commonService';
import { UserPorfolioService } from './infrastructure/services/userPortfolioService';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private userService: UsersService, private commonService: CommonService, private userPorfolioService: UserPorfolioService){}
  
  ngOnInit(): void {
    this.commonService.getCurrencies()
    .pipe(untilDestroyed(this))
    .subscribe(q => {
      this.commonService.$currencies.next(q);
    });
    
    if(this.userService.isUserAuthenticated()) {
      this.userService.sendAuthStateChangeNotification(true);
      this.userPorfolioService.initUserPortfolio().subscribe();
    }else {
      this.userService.sendAuthStateChangeNotification(false);
    }
  }
  // public title: string | undefined;

  // constructor(
  //   private router: Router,
  // ) {}

  // ngOnInit() {
  //   this.router.events
  //     .pipe(
  //       filter((event) => event instanceof NavigationEnd),
  //       map(() => {
  //         let route: ActivatedRoute = this.router.routerState.root;
  //         let routeTitle = '';
  //         while (route!.firstChild) {
  //           route = route.firstChild;
  //         }
  //         if (route.snapshot.data['title']) {
  //           routeTitle = route!.snapshot.data['title'];
  //         }
  //         return routeTitle;
  //       })
  //     )
  //     .subscribe((title: string) => {
  //       if (title) {
  //         this.title = title;
  //         //this.titleService.setTitle(`My App - ${title}`);
  //       }
  //     });
  // }
}
