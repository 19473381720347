<form [formGroup]="loginForm" autocomplete="off" novalidate (ngSubmit)="emailLogin(loginForm?.value)">
  <mat-card>
    <mat-card-title>Login</mat-card-title>
    <mat-card-content>
      <div class="card">
        <div class="card-body">
          <div *ngIf="showError" class="alert alert-danger" role="alert">
            {{errorMessage}}
          </div>

          <div fxLayout="column" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput type="email" id="username" formControlName="username">
              <div class="col-md-5">
                <em *ngIf="validateControl('username') && hasError('username', 'required')">Username is required</em>
              </div>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Password</mat-label>
              <input matInput type="password" id="password" formControlName="password">
              <div class="col-md-5">
                <em *ngIf="validateControl('password') && hasError('password', 'required')">Password is required</em>
              </div>
            </mat-form-field>
          </div>

          <br>
          <div class="mb-3 row">
            <div class="col-md-1">
              <button type="submit" [disabled]="!loginForm?.valid || loginLoading" mat-raised-button color="primary">
                <mat-icon *ngIf="loginLoading"><mat-spinner color="Warn" diameter="20"></mat-spinner></mat-icon>
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <div class="soc-buttons">
        <span class="google-button"><asl-google-signin-button type="standard" size="medium"
            class="mx-5"></asl-google-signin-button></span>
        <!-- <button class="btn btn-social-icon btn-facebook mx-1" (click)="signInWithFB()">
            FB
          </button> -->
      </div>
      <div class="reg-left"><a [routerLink]="['/auth/registration']" i18n> Registration </a></div>
      <div class="frgt-right"><a [routerLink]="['/auth/forgotpassword']" i18n> Forgot Password </a></div>

    </mat-card-actions>
  </mat-card>
</form>