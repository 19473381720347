import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  // public claims: [] = [];
  // constructor(private _repository: RepositoryService) { }
  // ngOnInit(): void {
  //   this.getClaims();
  // }
  // public getClaims = () =>{
  //   this._repository.getClaims('api/companies/privacy')
  //   .subscribe(res => {
  //     this.claims = res as [];
  //   })
  // }

}
