import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { CurrencyDto } from "../dtos/currencyDto";
import { BaseApiService } from "./BaseApiService";

@Injectable({
    providedIn: 'root',
})
export class CommonService extends BaseApiService {
    
    public $currencies: ReplaySubject<CurrencyDto[]> = new ReplaySubject<CurrencyDto[]>;

    constructor(private http: HttpClient) {
        super();
    }

    public getCurrencies(): Observable<CurrencyDto[]> {
        const url = `${this.BaseUrl}common/GetCurrencies` ;
        return this.http.get<CurrencyDto[]>(url);
    }
}