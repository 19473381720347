import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-delete-confirm',
  templateUrl: './dialog-delete-confirm.component.html',
  styleUrls: ['./dialog-delete-confirm.component.css']
})
export class DialogDeleteConfirmComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteConfirmComponent>
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
