import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AddStockToPortfolioDto } from "../dtos/Stock/addStockToPortfolio";
import { AddTradeToStockPortfolioDto } from "../dtos/Stock/addTradeToStockPortfolioDto";
import { StockPortfolioDto } from "../dtos/Stock/stockPortfolioDto";
import { StockPortfolioStockDataDto } from "../dtos/Stock/stockPortfolioStockDataDto";
import { StockPortfolioStockDto } from "../dtos/Stock/stockPortfolioStockDto";
import { StockTradeDto } from "../dtos/Stock/stockTradeDto";
import { StockSearchDto } from "../dtos/Stock/stockSearchDto";
import { BaseApiService } from "./BaseApiService";
import { StockPortfolioStockChartsDataDto } from "../dtos/Stock/stockPortfolioStockChartsDataDto";
import { UpcomingDividendDto } from "../dtos/Stock/upcomingDividendDto";
import { StockPortfolioChartsDataDto } from "../dtos/Stock/stockPortfolioChartsDataDto";

@Injectable({
    providedIn: 'root',
})
export class StocksService extends BaseApiService {
    
    private addedStockSub = new Subject<StockPortfolioDto>();
    public addedStockChanged = this.addedStockSub.asObservable();

    constructor(private http: HttpClient) {
        super();
    }

    public addStockToSubject(dto: StockPortfolioDto) {
        this.addedStockSub.next(dto);
    }

    public getStocksSearch(ticker: string): Observable<StockSearchDto[]> {
        const url = this.BaseUrl + 'Stocks';
        let queryParams = new HttpParams();
        queryParams = queryParams.append("ticker", ticker);
        return this.http.get<StockSearchDto[]>(url, {params: queryParams});
    }

    public getStockPortfolios(): Observable<StockPortfolioDto[]> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/GetStockPortfolios` ;
        return this.http.get<StockPortfolioDto[]>(url);
    }
    
    public createStockPortfolio(dto: StockPortfolioDto): Observable<StockPortfolioDto> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/CreateStockPortfolio` ;
        return this.http.post<StockPortfolioDto>(url, dto);
    }

    public addStockToPortfolio(dto: AddStockToPortfolioDto): Observable<StockPortfolioStockDto> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/AddStockToPortfolio` ;
        return this.http.post<StockPortfolioStockDto>(url, dto);
    }

    public deleteStockFromPortfolio(dto: StockPortfolioStockDto): Observable<void> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/DeleteStockFromPortfolio` ;
        return this.http.post<void>(url, dto);
    }

    // stock portfolio stock detail
    public addTradeToStockPortfolio(dto: AddTradeToStockPortfolioDto): Observable<StockTradeDto> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/AddTradeToStockPortfolio` ;
        return this.http.post<StockTradeDto>(url, dto);
    }

    public getStockPortfolioStockTrades(stockPortfolioStockId: number): Observable<StockPortfolioStockDto> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/GetStockPortfolioStockTrades` ;
        let queryParams = new HttpParams();
        queryParams = queryParams.append("stockPortfolioStockId", stockPortfolioStockId);
        return this.http.get<StockPortfolioStockDto>(url, {params: queryParams});
    }

    public getStockPortfolioStockData(stockPortfolioStockId: number): Observable<StockPortfolioStockDataDto> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/GetStockPortfolioStockData` ;
        let queryParams = new HttpParams();
        queryParams = queryParams.append("stockPortfolioStockId", stockPortfolioStockId);
        return this.http.get<StockPortfolioStockDataDto>(url, {params: queryParams});
    }

    public deleteTradeFromStockPortfolio(stockTradeId: number): Observable<void> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/DeleteTradeFromStockPortfolio/${stockTradeId}` ;
        return this.http.delete<void>(url);
    }

    public deleteStockPortfolio(stockPortfolioId: number): Observable<void> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/DeleteStockPortfolio/${stockPortfolioId}` ;
        return this.http.delete<void>(url);
    }

    public uploadTradesFile(file: any, stockPortfolioId: number, brokerId: number): Observable<void> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/UploadTradesFile` ;
        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('stockPortfolioId', stockPortfolioId.toString());
        formData.append('brokerId', brokerId.toString());
        return this.http.post<void>(url, formData);
    }

    public editStockPortfolioTrade(dto: StockTradeDto): Observable<StockTradeDto> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/EditStockPortfolioTrade` ;
        return this.http.post<StockTradeDto>(url, dto);
    }

    public getStockPortfolioStockChartsData(stockPortfolioStockId: number): Observable<StockPortfolioStockChartsDataDto> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/GetStockPortfolioStockChartsData` ;
        let queryParams = new HttpParams();
        queryParams = queryParams.append("stockPortfolioStockId", stockPortfolioStockId);
        return this.http.get<StockPortfolioStockChartsDataDto>(url, {params: queryParams});
    }

    public getStockPortfolioChartsData(stockPortfolioId: number): Observable<StockPortfolioChartsDataDto> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/GetStockPortfolioChartsData` ;
        let queryParams = new HttpParams();
        queryParams = queryParams.append("stockPortfolioId", stockPortfolioId);
        return this.http.get<StockPortfolioChartsDataDto>(url, {params: queryParams});
    }

    public GetUpcomingDividends(stockPortfolioId: number): Observable<UpcomingDividendDto[]> {
        const url = `${this.BaseUrl}Stocks/${this.getActivePortfolioId()}/GetUpcomingDividends` ;
        let queryParams = new HttpParams();
        queryParams = queryParams.append("stockPortfolioId", stockPortfolioId);
        return this.http.get<UpcomingDividendDto[]>(url, {params: queryParams});
    }
}