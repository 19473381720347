import { GoogleLoginProvider, SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { StockSearchDto } from "../dtos/Stock/stockSearchDto";
import { UserDto } from "../dtos/userDto";
import { AuthResponseDto } from "../dtos/Users/authResponseDto";
import { RegistrationResponseDto } from "../dtos/Users/registrationResponseDto";
import { UserEmailAuthenticationDto } from "../dtos/Users/userEmailAuthenticationDto";
import { UserEmailRegistrationDto } from "../dtos/Users/userEmailRegistrationDto";
import { UserMapper } from "../mappers/userMapper";
import { BaseApiService } from "./BaseApiService";
import { Subject } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ForgotPasswordDto } from "../dtos/Users/forgotPasswordDto";
import { ResetPasswordDto } from "../dtos/Users/resetPasswordDto ";
import { CustomEncoder } from "../encoders/customEncoder";
import { ExternalAuthDto } from "../dtos/Users/externalAuthDto";
import { CurrentUserDto } from "../dtos/Users/currentUserDto";

@Injectable({
    providedIn: 'root',
})
export class UsersService extends BaseApiService {

    private authChangeSub = new ReplaySubject<boolean>();
    private extAuthChangeSub = new Subject<SocialUser>();

    public authChanged = this.authChangeSub.asObservable();
    public extAuthChanged = this.extAuthChangeSub.asObservable();

    constructor(private http: HttpClient,
        private jwtHelper: JwtHelperService,
        private externalAuthService: SocialAuthService
    ) {
        super();

        this.externalAuthService.authState.subscribe((user) => {
            this.extAuthChangeSub.next(user);
        });
    }

    public getCurrentUser = (): CurrentUserDto => {
        const token = localStorage.getItem("token");
        const decodedToken = this.jwtHelper.decodeToken(token?.toString());

        const email = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        const id = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        // TODO:
        const userName = 'John Investor';

        return new CurrentUserDto(id, email, userName);
    }

    public isUserAdmin = (): boolean => {
        const token = localStorage.getItem("token");
        const decodedToken = this.jwtHelper.decodeToken(token?.toString());
        const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        return role === 'Administrator';
    }

    public isUserAuthenticated = (): boolean => {
        const token = localStorage.getItem("token");
        return !!token && !this.jwtHelper.isTokenExpired(token);
    }

    public sendAuthStateChangeNotification = (isAuthenticated: boolean) => {
        this.authChangeSub.next(isAuthenticated);
    }

    public registerUser = (body: UserEmailRegistrationDto) => {
        const url = this.BaseUrl + 'Users/registration';
        return this.http.post<RegistrationResponseDto>(url, body);
    }

    public forgotPassword = (body: ForgotPasswordDto) => {
        const url = this.BaseUrl + 'Users/forgotPassword';
        return this.http.post(url, body);
    }

    public resetPassword = (body: ResetPasswordDto) => {
        const url = this.BaseUrl + 'Users/resetPassword';
        return this.http.post(url, body);
    }

    public confirmEmail = (token: string, email: string) => {
        let params = new HttpParams({ encoder: new CustomEncoder() })
        params = params.append('token', token);
        params = params.append('email', email);
        const url = this.BaseUrl + 'Users/emailConfirmation';
        return this.http.get(url, { params: params });
    }

    public signInWithGoogle = () => {
        this.externalAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    public emailLoginUser = (body: UserEmailAuthenticationDto) => {
        const url = this.BaseUrl + 'Users/emailLogin';
        return this.http.post<AuthResponseDto>(url, body);
    }

    public externalLogin = (body: ExternalAuthDto) => {
        const url = this.BaseUrl + 'Users/externallogin';
        return this.http.post<AuthResponseDto>(url, body);
    }

    public signOutExternal = () => {
        this.externalAuthService.signOut();
    }

    public signOut(): void {
        localStorage.removeItem("token");
        localStorage.removeItem("activePortolio");
        this.sendAuthStateChangeNotification(false);
    }
}