
<div class="main-container"> 
    <mat-toolbar color="primary" class="topbar telative">
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                    <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">
                <span fxShow="false" fxShow.gt-xs>
                    <h1>Cashfy</h1>
                </span> 
              </a>
        </div>          
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container">
        <mat-sidenav-content class="page-wrapper">
            <!-- <mat-toolbar class="topbar telative toolbar-color">
                <span class="toolbar-content">
                </span>
            </mat-toolbar> -->
  
            <div class="page-content">
  
                <router-outlet></router-outlet>
                
            </div>    
               
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
  </div>
  