<!-- <a mat-button *ngIf="isUserAuthenticated && activeUserPortfolio" [matMenuTriggerFor]="userPortfolios"
    #menuTrigger="matMenuTrigger" class="selected-user-portfolio" (mouseover)="mouseUserPortfolioEnter(menuTrigger)"
    (mouseleave)="mouseUserPortfolioLeave(menuTrigger)" mat-icon-button href="javascript:void(0)">{{
    activeUserPortfolio?.name }}</a> -->
<a mat-button *ngIf="isUserAuthenticated && activeUserPortfolio" [matMenuTriggerFor]="userPortfolios"
    class="selected-user-portfolio" mat-icon-button href="javascript:void(0)">
    {{activeUserPortfolio?.name }}
</a>
<mat-menu #userPortfolios="matMenu" class="up-menu">
    <button mat-menu-item i18n (click)="addUserPortfolio()">
        <mat-icon>add_box</mat-icon> Add
    </button>
    <ng-container *ngFor="let userPortfolio of userPorfolios">
        <div mat-menu-item i18n>
            <button mat-button (click)="selectUserPortfolio(userPortfolio)">
                <mat-icon>account_box</mat-icon>
                <span class="name">{{ userPortfolio.name }}</span>
            </button>
            <button mat-button (click)="editUserPortfolio(userPortfolio.id)" class="small-icon-button" #tooltip="matTooltip" matTooltip="Edit"> 
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-button (click)="deleteUserPortfolio(userPortfolio)" class="small-icon-button" #tooltip="matTooltip" matTooltip="Delete">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <!-- <button mat-menu-item i18n (click)="editUserPortfolio(userPortfolio.id)">
            <mat-icon>settings</mat-icon>
        </button>
        <button mat-menu-item i18n (click)="deleteUserPortfolio(userPortfolio.id)">
            <mat-icon>exit_to_app</mat-icon>
        </button> -->
    </ng-container>
</mat-menu>

<a mat-button *ngIf="isUserAuthenticated && activeUserPortfolio" [matMenuTriggerFor]="profile" class="m-r-5"
    href="javascript:void(0)">{{ userName }}</a>

<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item i18n>
        <mat-icon>settings</mat-icon> Settings
    </button>
    <button mat-menu-item i18n>
        <mat-icon>account_box</mat-icon> Profile
    </button>
    <button mat-menu-item i18n>
        <mat-icon>notifications_off</mat-icon> Disable notifications
    </button>
    <button mat-menu-item i18n (click)="signOut()">
        <mat-icon>exit_to_app</mat-icon> Sign Out
    </button>
</mat-menu>